.shepherd-content {
  background: #1d2335 !important;
  box-shadow: 0px 2px 4px 1px rgba(255, 255, 255, 0.3);
  border-radius: 4px;
}

.shepherd-content .shepherd-header {
  background: linear-gradient(
    135.56deg,
    rgba(4, 10, 30, 0) -30.65%,
    rgba(108, 56, 255, 0.44) 96.51%
  ) !important;
  border-radius: 3px 3px 0 0;
}

.shepherd-content .shepherd-header h3 {
  color: white;
  font-weight: bold;
}

.shepherd-content .shepherd-header button span {
  color: rgb(178, 178, 178);
}
.shepherd-content .shepherd-header button:hover span {
  color: white;
}

.shepherd-content .shepherd-text {
  padding-top: 1.3em;
  padding-bottom: 1.3em;
  color: white;
}

.shepherd-element {
  border: 0;
  padding: 0;
  background: unset;
}

.shepherd-button {
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 200px;
  transition: all 0.3s cubic-bezier(1, 0, 0.58, 1);
  font-weight: 600;
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.shepherd-button:focus,
.shepherd-button:hover {
  color: #000000 !important;
  border: 1px solid #f8fb67;
  background: #f8fb67 !important;
}
